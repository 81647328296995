import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { APP_VIEWCONTAINERREF_CHANGED, EmitterService, lazyModuleLoadingChanged } from '@myia/ngx-core';
import { CultureService, LocalizationService } from '@myia/ngx-localization';
import { FloatingElementService, PWAUpdateService, StatusBarManager } from '@shared';
import { PageTitleService } from './pages/shared/services/pageTitleService';

@Component({
    selector: 'portal-app',
    styleUrls: ['./portalApp.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="portalAppRoot" trackMouseFocus>
            <div class="loadingModule" *ngIf="loadingModule">{{'Loading'|trans}}</div>
            <div class="portalViewContent" [ngClass]="{loading: loadingModule}">
                <router-outlet></router-outlet>
            </div>
            <context-menu-holder></context-menu-holder>
            <status-bar *ngIf="loadingModule"></status-bar>
        </div>
        <ng-template let-dialog dialogIdentifier="confirmationDialog">
            <div class="dialogMessage">{{dialog.dialogData}}</div>
            <div class="dialogButtons">
                <button type="button" class="dialogButtonSecondary dialogButton" (click)="dialog.close()">{{'No'| trans}}</button>
                <button type="button" class="dialogButtonPrimary dialogButton" (click)="dialog.close(true)">{{'Yes'| trans}}</button>
            </div>
        </ng-template>
        <div class="floatingContainer" freeDragging [boundaryElement]="floatingElementService.currentBoundaryElement | async" [dragPadding]="{left: 30, top: 20, right: 20, bottom: 20}" (movedTo)="floatingPanelMovedTo($event)">
            <div class="floatingTopBar"><div class="title" (click)="floatingContainerHandleClicked($event)"><svg-icon name="player"></svg-icon>{{'General.Live_Short'|trans}}</div><button type="button" class="closeBtn" (click)="closeFloatingContainer($event)"><svg-icon name="cancel"></svg-icon></button></div>
            <ng-container #floatingContainer></ng-container>
        </div>
    `
})
export class PortalAppComponent implements AfterViewInit {

    @HostBinding('class.appEl') hostClass = true;

    @ViewChild('floatingContainer', { read: ViewContainerRef }) floatingContainer?: ViewContainerRef;

    loadingModule = false;

    // inject PWAUpdateService to check new app versions
    constructor(private _changeDetectorRef: ChangeDetectorRef, vcRef: ViewContainerRef, pwaUpdateService: PWAUpdateService, private _router: Router, private _statusBarManager: StatusBarManager, private _pageTitleService: PageTitleService, private _cultureService: CultureService, private _localizationService: LocalizationService, public floatingElementService: FloatingElementService) {

        // notify other components about root app view container (e.g. ModalDialog, GestureModule)
        EmitterService.getValueChangedEvent<ViewContainerRef>(APP_VIEWCONTAINERREF_CHANGED).next(vcRef);
        lazyModuleLoadingChanged(this._router, 'portal').subscribe(loading => {
            this.loadingModule = loading;
            this._statusBarManager.showBusyLine(loading, 'portalLazyLoading');
            this._changeDetectorRef.detectChanges();
        });
    }

    ngAfterViewInit() {
        this.floatingElementService.registerFloatingContainer(this.floatingContainer);
    }

    @HostListener('dragenter', ['$event'])
    onDragEnterBody($event: DragEvent) {
        return this.cancelDragOperation($event);
    }

    @HostListener('dragover', ['$event'])
    onDragOverBody($event: DragEvent) {
        return this.cancelDragOperation($event);
    }

    closeFloatingContainer($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        this.floatingElementService.unregisterFloatingElement();
    }

    floatingContainerHandleClicked($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();
    }

    floatingPanelMovedTo(pos: { x: number; y: number }) {
        this.floatingElementService.setMinimizedPosition(pos);
    }

    private cancelDragOperation($event: DragEvent) {
        $event.stopPropagation();
        $event.preventDefault();
        if ($event.dataTransfer) {
            $event.dataTransfer.dropEffect = 'none'; // disable drop by default
        }
        return false;
    }

}

