import { Routes } from '@angular/router';
import { Customers, DashboardRoutePath, EventRoutePath, LoginRoutePath, LogoutRoutePath } from '@shared';
import { PortalAppRedirectComponent } from './portalApp.Redirect';

export const MainAppRoutes: Routes = [
    // {
    //     path: AccessDeniedRoutePath,
    //     loadChildren: () => import('./pages/accessDenied/accessDenied.Module').then(m => m.AccessDeniedModule)
    // },
    {
        path: DashboardRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'dashboard.module' */'./pages/dashboard/dashboard.Module').then(m => m.DashboardModule),
    },
    {
        path: `${EventRoutePath}/:eventId`,
        loadChildren: () => import(/* webpackChunkName: 'event.module' */'./pages/event/event.Module').then(m => m.EventModule),
    },
    // {
    //     path: LoginQRRoutePath,
    //     loadChildren: () => import(/* webpackChunkName: 'login.module' */'./pages/login/login.Module').then(m => m.LoginModule),
    // },
    {
        path: LoginRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'login.module' */'./pages/loginEmail/loginEmail.Module').then(m => m.LoginEmailModule),
    },
    {
        path: LogoutRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'logout.module' */'./pages/logout/logout.Module').then(m => m.LogoutModule),
    },
    {
        path: Customers.Pref2019.LoginRoutePath,
        loadChildren: () => import(/* webpackChunkName: 'pref2019.module' */'./pages/@customers/pref2019/pref2019.Module').then(m => m.Pref2019Module),
    },
    // redirect all other urls to dashboard
    {
        path: '**',
        component: PortalAppRedirectComponent
    }
];
