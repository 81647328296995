// external modules
import { APP_BASE_HREF } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
    CORE_CONFIGURATION,
    CoreModule,
    Level,
    provideLazyRoutes,
    ROUTES_LEVEL,
    setReadOnlyEntityProdMode
} from '@myia/ngx-core';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { DialogModule } from '@myia/ngx-dialog';
import { HttpModule, ITOKEN_SERVICE } from '@myia/ngx-http';
import {
    ILocalizationConfig,
    LOCALIZATION_CONFIGURATION,
    LocalizationModule,
    TranslationLoaderFactory,
    TranslateJSONLoader
} from '@myia/ngx-localization';
import { REDUX_MODULE_CONFIG, ReduxModule, ReduxStore } from '@myia/ngx-redux';
//import { SIGNAL_R_CONFIGURATION, SignalRModule } from '@myia/ngx-signal-r';
import { SplashModule } from '@myia/ngx-splash';
import { ToastModule } from '@myia/ngx-toast';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    AppConfig,
    AppMode,
    authReducerKey, cultureReducerKey,
    IAuthState,
    ICultureState, setCulture,
    TokenService,
    ViewSharedComponentsModule
} from '@shared';
import { Observable, Observer } from 'rxjs';
import { PortalAppComponent } from './portalApp.Component';
import { PortalAppRedirectComponent } from './portalApp.Redirect';
import { MainAppRoutes } from './portalApp.Routes';
import { MainAppRoutesModerator } from './portalApp.Routes.Moderator';
// Myia portal styles
import './styles/index.scss';
import { map } from 'rxjs/operators';

function requireAll(r: any) {
    r.keys().forEach(r);
}

// @ts-ignore
requireAll(require['context']('./icons/svg/', true, /\.svg$/));

/* Redux configuration - must be provided as factory to be AOT compatible */
export function provideReducers(): Array<any> {
    return [];
}

export function providePersistedReducersNames(): Array<string> {
    return [];
}

export function ReduxConfigFactory() {
    return {
        reduxStorageKey: AppConfig.reduxStorageKey,
        useLogger: !AppConfig.isProd
    };
}

/* Localization configuration */
export function LocalizationConfigFactory() : ILocalizationConfig {
    const cultures = AppConfig.cultures;
    return {
        defaultCulture: AppConfig.defaultCulture,
        supportedCultures: cultures ? cultures.split('|') : null,
        translateLoaderFactory: TranslateLoaderFactory,
        getStoredCulture : (injector: Injector) => {
            const store = injector.get(ReduxStore);
            return store.loaded.pipe(map(() => {
                const cultureState = store.getState<ICultureState>(cultureReducerKey);
                return cultureState?.culture;
            }));
        },
        storeCulture: (injector: Injector, culture: string) => {
            console.log(`Storing culture ${culture}`);
            const store = injector.get(ReduxStore);
            store.dispatch(setCulture(culture));
        }
    };
}

/* SignalR configuration */
export function SignalRConfigFactory() {
    return {
        signalRUrl: AppConfig.BASE_API_URL
    };
}

export function TranslateLoaderFactory() {
    return new TranslateJSONLoader((lang: string) => {
        return new Observable((observer: Observer<any>) => {
            import(`./locale/${lang}/translations.json`).then(translations => {
                observer.next({...translations});
                observer.complete();
            });
        });
    });
}

const pwaEnabled = AppConfig.PWA;
const appRoutes = AppConfig.appMode === AppMode.myiaModerator ? MainAppRoutesModerator : MainAppRoutes;

if (AppConfig.isProd) {
    setReadOnlyEntityProdMode();
}

const toastSvgSymbols = require('!!raw-loader?!@myia/ngx-toast/src/lib/icons/symbols/toast.symbol.svg').default;

@NgModule({
    declarations: [
        PortalAppComponent,
        PortalAppRedirectComponent
    ],
    imports: [
        // animations
        BrowserAnimationsModule,
        BrowserModule, //  CommonModule,
        // Core config
        CoreModule.forRoot({
            logLevel: Level.ERROR
        }),
        CoreUIModule.forRoot(),
        DialogModule,
        HttpModule.forRoot({
            baseUrl: AppConfig.BASE_API_URL,
            getStoredToken: (injector: Injector) => {
                const store = injector.get(ReduxStore);
                return store.loaded.pipe(map(() => {
                    const authState = store.getState<IAuthState>(authReducerKey);
                    return authState.token;
                }));
            }
        }),
        // Toast module
        ToastModule.forRoot({timeOut: 3000, cssClass: 'toastMessage', toastIconsSvg: toastSvgSymbols}),
        // 3rd party translation module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslationLoaderFactory,
                deps: [Injector]
            }
        }),

        // Localization module
        LocalizationModule.forRoot({
            config: {
                provide: LOCALIZATION_CONFIGURATION,
                useFactory: LocalizationConfigFactory
            }
        }),
        // Redux module
        ReduxModule.forRoot(provideReducers, providePersistedReducersNames, {
            config: {
                provide: REDUX_MODULE_CONFIG,
                useFactory: ReduxConfigFactory,
                deps: [
                    CORE_CONFIGURATION // !important - redux config depends on core config initialization
                ]
            }
        }),
        // Splash module
        SplashModule.forRoot({
            splashSelector: 'splash'
        }),
        // PWA support
        ServiceWorkerModule.register('/service-worker.js', {
            enabled: pwaEnabled
        }),
        // // SignalR module
        // SignalRModule.forRoot({
        //     config: {
        //         provide: SIGNAL_R_CONFIGURATION,
        //         useFactory: SignalRConfigFactory
        //     }
        // }),

        ViewSharedComponentsModule,
        // Router
        RouterModule.forRoot([], {
            enableTracing: true,
            useHash: false,
            paramsInheritanceStrategy: 'always' // inherit route parameters to child routes (e.g. eventId)
        }),
    ],
    bootstrap: [PortalAppComponent],
    providers: [
        // {provide: AuthConfig, useFactory: () => { return new AuthConfig(); }},

        {provide: APP_BASE_HREF, useValue: ''},

        // providers used by http module
        {provide: ITOKEN_SERVICE, useClass: TokenService},
        {provide: TokenService, useExisting: ITOKEN_SERVICE},

        {
            provide: ROUTES_LEVEL,
            useValue: appRoutes
        },



...provideLazyRoutes(appRoutes, 'portal')
    ]
})
export class PortalAppModule {
}
